import React from "react";
import ArrowLeftIcon from "../assets/icons/arrow-left.svg";

export default ({ setMode }) => {
  return (
    <div className="flex-none w-16 pl-2">
      <button
        className="px-2 py-2 bg-gray-100 mt-4 hover:bg-gray-200"
        onClick={(e) => setMode("")}
      >
        <img src={ArrowLeftIcon} className="w-8 h-8" alt="encryption" />
      </button>
    </div>
  );
};
