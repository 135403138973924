import React from "react";
import CryptoJS from "crypto-js";
import TextareaAutosize from "react-textarea-autosize";
import EncHeader from "./EncHeader.jsx";
import BackBtn from "./BackBtn.jsx";

export default ({ setMode }) => {
  const [passphrase, setPassphrase] = React.useState("");
  const [content, setContent] = React.useState("");

  const [converted, setConverted] = React.useState("");

  const encode = (content, passphrase) => {
    if (!content.length || !passphrase.length) return setConverted("");

    setConverted(CryptoJS.AES.encrypt(content, passphrase).toString());
  };

  return (
    <div className="two-column-wrapper">
      {/* Column left */}
      <div className="two-column-left">
        <BackBtn setMode={setMode} />
        {/* User input */}
        <div className="flex-1">
          <div>
            <div className="w-full">
              <EncHeader />

              <hr className="my-6" />

              <h3 className="text-sm mb-2 text-primary-blue">
                Text to encrypt:
              </h3>
              <TextareaAutosize
                placeholder="e.g.: I had a crazy dream about ..."
                value={content}
                onClick={(e) => e.target.select()}
                onChange={(e) => {
                  setContent(e.target.value);
                  encode(e.target.value, passphrase);
                }}
              />

              <h3 className="text-sm mb-2 text-primary-blue mt-6">
                Set a passphrase:
              </h3>
              <input
                className="focus:bg-yellow-200"
                placeholder="e.g.: 12345678"
                type="text"
                value={passphrase}
                onClick={(e) => e.target.select()}
                onChange={(e) => {
                  setPassphrase(e.target.value);
                  encode(content, e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Column right */}
      {converted.length > 0 && (
        <div className="two-column-right">
          {/* Vertical middle */}
          <div>
            <h3 className="text-lh font-bold my-2">Encrypted Output:</h3>
            <TextareaAutosize
              className="w-full text-primary-blue font-bold bg-blue-100 border-gray-700"
              readOnly="readonly"
              placeholder="Encrypted result will show here"
              value={converted}
              onClick={(e) => e.target.select()}
            />
          </div>
        </div>
      )}
    </div>
  );
};
