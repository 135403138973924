import React from "react";
import DecryptionIcon from "../assets/icons/decryption.svg";
export default () => {
  return (
    <div className="flex justify-start items-center">
      <span className="mr-6">
        <img src={DecryptionIcon} className="w-14 h-14" alt="decryption" />
      </span>
      <div className="text-left">
        <h2 className="text-2xl leading-8 font-bold">Decrypt</h2>
        <h5 className="text-gray-500">a given text with passphrase</h5>
      </div>
    </div>
  );
};
