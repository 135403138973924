import React from "react";
import SideBar from "./components/SideBar.jsx";
import ChooseMode from "./components/ChooseMode.jsx";
import Enc from "./components/Enc.jsx";
import Dec from "./components/Dec.jsx";

export default function App() {
  const [mode, setMode] = React.useState("");

  // const doneCallback = index => {
  //   const newTodos = [...tasks];
  //   newTodos[index].done = true;
  //   setTodos(newTodos);
  // }

  return (
    <div className="min-h-screen flex overflow-hidden">
      <SideBar />

      <div className="app-content">
        {mode === "" && <ChooseMode setMode={setMode} />}
        {mode === "enc" && <Enc setMode={setMode} />}
        {mode === "dec" && <Dec setMode={setMode} />}
      </div>
    </div>
  );
}
