import React from "react";
import CryptoJS from "crypto-js";
import TextareaAutosize from "react-textarea-autosize";
import DecHeader from "./DecHeader.jsx";
import BackBtn from "./BackBtn.jsx";

export default ({ setMode }) => {
  const [passphrase, setPassphrase] = React.useState("");
  const [content, setContent] = React.useState("");

  const [converted, setConverted] = React.useState("");

  const decode = (content, passphrase) => {
    if (!content.length || !passphrase.length) return setConverted("");

    try {
      const decrypted = CryptoJS.AES.decrypt(content, passphrase);

      setConverted(decrypted.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      //console.error(error);
      setConverted("");
    }
  };

  return (
    <div className="two-column-wrapper">
      {/* Column left */}
      <div className="two-column-left">
        <BackBtn setMode={setMode} />
        {/* User input */}
        <div className="flex-1">
          <div>
            <div className="w-full">
              <DecHeader />

              <hr className="my-6" />

              <h3 className="text-sm mb-2 text-primary-blue">
                Text to decrypt:
              </h3>
              <TextareaAutosize
                placeholder="e.g: U2FsdGVkX1/iYaXJ0MSR="
                value={content}
                onClick={(e) => e.target.select()}
                onChange={(e) => {
                  setContent(e.target.value);
                  decode(e.target.value, passphrase);
                }}
              />

              <h3 className="text-sm mb-2 text-primary-blue mt-6">
                Passphrase:
              </h3>
              <input
                className="focus:bg-yellow-200"
                placeholder="e.g.: 12345678"
                type="text"
                value={passphrase}
                onClick={(e) => e.target.select()}
                onChange={(e) => {
                  setPassphrase(e.target.value);
                  decode(content, e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Column right */}
      {content.length > 0 && passphrase.length > 0 && converted.length > 0 && (
        <div className="two-column-right">
          <div>
            <h3 className="text-lh font-bold my-2">Decrypted Result:</h3>
            <TextareaAutosize
              className="w-full text-primary-green font-bold bg-green-100 border-gray-700"
              readOnly="readonly"
              placeholder="Decrypted result will show here"
              value={converted}
              onClick={(e) => e.target.select()}
            />
          </div>
        </div>
      )}

      {/* Unable to decrypt */}
      {content.length > 0 && passphrase.length > 0 && converted.length < 1 && (
        <div className="two-column-right">
          <div>
            <p className="text-gray-500">
              Unable to decrypt. Please make sure you have the correct
              passphrase.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
