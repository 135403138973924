import React from "react";
import EncryptionIcon from "../assets/icons/encryption.svg";

export default () => {
  return (
    <div className="flex justify-start items-center">
      <span className="mr-6">
        <img src={EncryptionIcon} className="w-14 h-14" alt="encryption" />
      </span>
      <div className="text-left">
        <h2 className="text-2xl leading-8 font-bold">Encrypt</h2>
        <h5 className="text-gray-500">any text of your choice</h5>
      </div>
    </div>
  );
};
