import React from "react";
import DecryptionIcon from "../assets/icons/decryption.svg";
import EncryptionIcon from "../assets/icons/encryption.svg";

export default ({ setMode }) => {
  return (
    <div className="flex flex-wrap bg-white w-full pt-32 md:min-h-screen md:pt-0 md:content-center px-10 md:px-16 lg:px-32 pb-10">
      <div className="w-full  ">
        <p className="text-2xl font-bold mb-6">Choose a service</p>

        <button onClick={(e) => setMode("enc")} className="btn-choose">
          <span>
            <img src={EncryptionIcon} className="w-12 h-12" alt="encryption" />
          </span>
          <div>
            <h2>Encrypt</h2>
            <h5>any text of your choice</h5>
          </div>
        </button>

        <button onClick={(e) => setMode("dec")} className="btn-choose">
          <span>
            <img src={DecryptionIcon} className="w-12 h-12" alt="decryption" />
          </span>
          <div>
            <h2>Decrypt</h2>
            <h5>a given text with passphrase</h5>
          </div>
        </button>
      </div>
    </div>
  );
};
